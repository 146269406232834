export const ROUTES = {
  main: '/',
  viewer: '/viewer',
  orders: '/orders',
  ordersViewer: '/orders/viewer',
  admin: '/admin',
  login: '/login',
  serviceRequests: '/service-requests',
  cookieDeclaration: '/cookie-declaration',
};

export const PAGES_TITLES = {
  admin: 'Admin',
  serviceRequests: 'Service Requests',
  orders: 'My Orders',
} satisfies Partial<Record<keyof typeof ROUTES, string>>;
