import * as Types from '../../schema.types';

import gql from 'graphql-tag';
import { ProductGroupsProductGroup } from '../../../pages/AdminPage/ProductGroups/ProductGroups.urql.generated';
import { GenresPageGenre } from '../../../pages/AdminPage/GenresPage/GenresPage.urql.generated';
import { BrandsPageBrand } from '../../../pages/AdminPage/BrandsPage/BrandsPage.urql.generated';
import { TenantsPageTenant } from '../../../pages/AdminPage/TenantsPage/TenantsPage.urql.generated';
import { ProvisioningListOverview } from '../../../pages/AdminPage/UserProvisioningListsPage/UserProvisioningListsPage.urql.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CacheProductGroupsVariables = Types.Exact<{ [key: string]: never; }>;


export type CacheProductGroups = { __typename?: 'Query', productGroups: Array<{ __typename?: 'ProductGroupModel', id: string, name: string, products: Array<{ __typename?: 'InfoModel', id: string, name: string }>, users: Array<{ __typename?: 'InfoModel', id: string, name: string }> }> };

export type CacheProductGroupsProductGroup = { __typename?: 'ProductGroupModel', id: string, name: string, products: Array<{ __typename?: 'InfoModel', id: string, name: string }>, users: Array<{ __typename?: 'InfoModel', id: string, name: string }> };

export type CacheGenresVariables = Types.Exact<{ [key: string]: never; }>;


export type CacheGenres = { __typename?: 'Query', genres: Array<{ __typename?: 'GenreModel', id: string, name: string, color: string, logoUrl: string }> };

export type CacheGenresGenre = { __typename?: 'GenreModel', id: string, name: string, color: string, logoUrl: string };

export type CacheBrandsVariables = Types.Exact<{ [key: string]: never; }>;


export type CacheBrands = { __typename?: 'Query', brands: Array<{ __typename?: 'BrandModel', id: string, name: string, color: string, logoUrl: string }> };

export type CacheBrandsBrand = { __typename?: 'BrandModel', id: string, name: string, color: string, logoUrl: string };

export type CacheTenantsVariables = Types.Exact<{ [key: string]: never; }>;


export type CacheTenants = { __typename?: 'Query', managedTenants: Array<{ __typename?: 'TenantModel', domain?: string | null, id: string, name: string, noMatchesImageUrl: string, userJourney?: string | null, logoUrl?: string | null, projectManagers: Array<{ __typename?: 'InfoModel', id: string, name: string }> }> };

export type CacheTenantsTenant = { __typename?: 'TenantModel', domain?: string | null, id: string, name: string, noMatchesImageUrl: string, userJourney?: string | null, logoUrl?: string | null, projectManagers: Array<{ __typename?: 'InfoModel', id: string, name: string }> };

export type CacheUserProvisioningTablesVariables = Types.Exact<{ [key: string]: never; }>;


export type CacheUserProvisioningTables = { __typename?: 'Query', userProvisioningTables: Array<{ __typename?: 'UserProvisioningTableModel', id: string, name: string, createdAt: string, items: Array<{ __typename?: 'UserProvisioningTableItemModel', email: string, processed: boolean }>, createdBy: { __typename?: 'UserModel', id: string, email: string } }> };

export type CacheUserProvisioningTable = { __typename?: 'UserProvisioningTableModel', id: string, name: string, createdAt: string, items: Array<{ __typename?: 'UserProvisioningTableItemModel', email: string, processed: boolean }>, createdBy: { __typename?: 'UserModel', id: string, email: string } };

export type CacheUserProfileVariables = Types.Exact<{ [key: string]: never; }>;


export type CacheUserProfile = { __typename?: 'Query', userProfile: { __typename?: 'UserProfileModel', welcomeMessageShown: boolean } };

export type _RefiredPreview = { __typename?: 'PreviewModel', id: string, failureReason?: string | null, status: Types.PreviewStatus };

export type CacheOrderFragment = { __typename?: 'OrderModel', id: string, isDeleted: boolean, orderItems: Array<{ __typename?: 'OrderItemModel', id: string, isDeleted: boolean }> };

export const CacheProductGroupsProductGroup = gql`
    fragment CacheProductGroupsProductGroup on ProductGroupModel {
  ...ProductGroupsProductGroup
}
    ${ProductGroupsProductGroup}`;
export const CacheGenresGenre = gql`
    fragment CacheGenresGenre on GenreModel {
  ...GenresPageGenre
}
    ${GenresPageGenre}`;
export const CacheBrandsBrand = gql`
    fragment CacheBrandsBrand on BrandModel {
  ...BrandsPageBrand
}
    ${BrandsPageBrand}`;
export const CacheTenantsTenant = gql`
    fragment CacheTenantsTenant on TenantModel {
  ...TenantsPageTenant
}
    ${TenantsPageTenant}`;
export const CacheUserProvisioningTable = gql`
    fragment CacheUserProvisioningTable on UserProvisioningTableModel {
  ...ProvisioningListOverview
}
    ${ProvisioningListOverview}`;
export const _RefiredPreview = gql`
    fragment _RefiredPreview on PreviewModel {
  id
  failureReason
  status
}
    `;
export const CacheOrderFragment = gql`
    fragment CacheOrderFragment on OrderModel {
  id
  isDeleted
  orderItems {
    id
    isDeleted
  }
}
    `;
export const CacheProductGroupsDocument = gql`
    query CacheProductGroups {
  productGroups {
    ...CacheProductGroupsProductGroup
  }
}
    ${CacheProductGroupsProductGroup}`;

export function useCacheProductGroups(options?: Omit<Urql.UseQueryArgs<CacheProductGroupsVariables>, 'query'>) {
  return Urql.useQuery<CacheProductGroups, CacheProductGroupsVariables>({ query: CacheProductGroupsDocument, ...options });
};
export const CacheGenresDocument = gql`
    query CacheGenres {
  genres {
    ...CacheGenresGenre
  }
}
    ${CacheGenresGenre}`;

export function useCacheGenres(options?: Omit<Urql.UseQueryArgs<CacheGenresVariables>, 'query'>) {
  return Urql.useQuery<CacheGenres, CacheGenresVariables>({ query: CacheGenresDocument, ...options });
};
export const CacheBrandsDocument = gql`
    query CacheBrands {
  brands {
    ...CacheBrandsBrand
  }
}
    ${CacheBrandsBrand}`;

export function useCacheBrands(options?: Omit<Urql.UseQueryArgs<CacheBrandsVariables>, 'query'>) {
  return Urql.useQuery<CacheBrands, CacheBrandsVariables>({ query: CacheBrandsDocument, ...options });
};
export const CacheTenantsDocument = gql`
    query CacheTenants {
  managedTenants {
    ...CacheTenantsTenant
  }
}
    ${CacheTenantsTenant}`;

export function useCacheTenants(options?: Omit<Urql.UseQueryArgs<CacheTenantsVariables>, 'query'>) {
  return Urql.useQuery<CacheTenants, CacheTenantsVariables>({ query: CacheTenantsDocument, ...options });
};
export const CacheUserProvisioningTablesDocument = gql`
    query CacheUserProvisioningTables {
  userProvisioningTables {
    ...CacheUserProvisioningTable
  }
}
    ${CacheUserProvisioningTable}`;

export function useCacheUserProvisioningTables(options?: Omit<Urql.UseQueryArgs<CacheUserProvisioningTablesVariables>, 'query'>) {
  return Urql.useQuery<CacheUserProvisioningTables, CacheUserProvisioningTablesVariables>({ query: CacheUserProvisioningTablesDocument, ...options });
};
export const CacheUserProfileDocument = gql`
    query CacheUserProfile {
  userProfile {
    welcomeMessageShown
  }
}
    `;

export function useCacheUserProfile(options?: Omit<Urql.UseQueryArgs<CacheUserProfileVariables>, 'query'>) {
  return Urql.useQuery<CacheUserProfile, CacheUserProfileVariables>({ query: CacheUserProfileDocument, ...options });
};